// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-content {
    display: none;
}

@media(max-width: 700px) {
    .menu {
    position: fixed;
    width: 100vh;
    height: 100%;
    top: 60px;
    left: 0;
    transform: translateX(-130%);
    transition: all 0.3s;
    }

    .menu.active {
        transform: translateX(0);
    }

    .blur {
        width: 100vh;
        height: 100vh;
        left: 30%;
        -webkit-backdrop-filter: blur(2px);
                backdrop-filter: blur(2px);
        position: absolute;    
    }

    .menu-content {
        width: 30%;
        height: 100%;
        background-color: #dcd3ba;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;    
        transition: all 0.4s;
        position: relative;
    }

    .menu-item {
        margin: 5px 0;
        text-decoration: none;
        color: #241D11;
        background-color: #F2F1ED;
        width: 80%;
        padding: 15px 0;
        display: flex;
        justify-content: center;
        border-radius: 5px;
    }

}
`, "",{"version":3,"sources":["webpack://./src/css/Menu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI;IACA,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,OAAO;IACP,4BAA4B;IAC5B,oBAAoB;IACpB;;IAEA;QACI,wBAAwB;IAC5B;;IAEA;QACI,YAAY;QACZ,aAAa;QACb,SAAS;QACT,kCAA0B;gBAA1B,0BAA0B;QAC1B,kBAAkB;IACtB;;IAEA;QACI,UAAU;QACV,YAAY;QACZ,yBAAyB;QACzB,aAAa;QACb,sBAAsB;QACtB,mBAAmB;QACnB,iBAAiB;QACjB,oBAAoB;QACpB,kBAAkB;IACtB;;IAEA;QACI,aAAa;QACb,qBAAqB;QACrB,cAAc;QACd,yBAAyB;QACzB,UAAU;QACV,eAAe;QACf,aAAa;QACb,uBAAuB;QACvB,kBAAkB;IACtB;;AAEJ","sourcesContent":[".menu-content {\n    display: none;\n}\n\n@media(max-width: 700px) {\n    .menu {\n    position: fixed;\n    width: 100vh;\n    height: 100%;\n    top: 60px;\n    left: 0;\n    transform: translateX(-130%);\n    transition: all 0.3s;\n    }\n\n    .menu.active {\n        transform: translateX(0);\n    }\n\n    .blur {\n        width: 100vh;\n        height: 100vh;\n        left: 30%;\n        backdrop-filter: blur(2px);\n        position: absolute;    \n    }\n\n    .menu-content {\n        width: 30%;\n        height: 100%;\n        background-color: #dcd3ba;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        padding-top: 20px;    \n        transition: all 0.4s;\n        position: relative;\n    }\n\n    .menu-item {\n        margin: 5px 0;\n        text-decoration: none;\n        color: #241D11;\n        background-color: #F2F1ED;\n        width: 80%;\n        padding: 15px 0;\n        display: flex;\n        justify-content: center;\n        border-radius: 5px;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
